<template>
	<div class="footer">
		<div class="fh">核心夥伴</div>
		<!-- <div  style="height: 200rpx;"></div> -->
		<div class="piny" >
			<div class="trans">
				<img src="../../src/assets/logins/8.210e67dd.svg" mode=""/>
				<img src="../../src/assets/logins/1.135b5f7c.svg" mode=""/>
				<img src="../../src/assets/logins/2.20273095.svg" mode=""/>
				<img src="../../src/assets/logins/3.80ef0dd0.svg" mode=""/>
				<img src="../../src/assets/logins/6.87e04b20.svg" mode=""/>
				<img src="../../src/assets/logins/7.0ea7ddc4.svg" mode=""/>
				<img src="../../src/assets/logins/9.ca50e3f1.svg" mode=""/>
				<img src="../../src/assets/logins/11.1f5f6766.svg" mode=""/>
				<img src="../../src/assets/logins/12.e5d051b8.svg" mode=""/>
				<img src="../../src/assets/logins/13.0da5325d.svg" mode=""/>
				<img src="../../src/assets/logins/14.713f544d.svg" mode=""/>
			</div>
			<div class="trans" style="margin-left:80rpx;">
				<img src="../../src/assets/logins/8.210e67dd.svg" mode=""/>
				<img src="../../src/assets/logins/1.135b5f7c.svg" mode=""/>
				<img src="../../src/assets/logins/2.20273095.svg" mode=""/>
				<img src="../../src/assets/logins/3.80ef0dd0.svg" mode=""/>
				<img src="../../src/assets/logins/6.87e04b20.svg" mode=""/>
				<img src="../../src/assets/logins/7.0ea7ddc4.svg" mode=""/>
				<img src="../../src/assets/logins/9.ca50e3f1.svg" mode=""/>
				<img src="../../src/assets/logins/11.1f5f6766.svg" mode=""/>
				<img src="../../src/assets/logins/12.e5d051b8.svg" mode=""/>
				<img src="../../src/assets/logins/13.0da5325d.svg" mode=""/>
				<img src="../../src/assets/logins/14.713f544d.svg" mode=""/>
			</div>
		</div>
		<div class="xian"></div>
		<div class="flist">
			<div class="left">
				<img src="../../src/assets/img/mr.png" mode=""/>
				<div class="tit">
					打破傳統商業模式障礙、設
					定全新交互方式、重新定義
					亞洲數位世代、徹底顛覆亞
					洲固有商業生態系统。
				</div>
			</div>
			<div class="center">
				<div class="ch">聯络Onez</div>
				<div class="fimg">
					<img src="../../src/assets/img/f1.png" mode=""/>
					<img src="../../src/assets/img/f2.png" mode=""/>
					<img src="../../src/assets/img/f3.png" mode=""/>
					<img src="../../src/assets/img/f4.png" mode=""/>
					<img src="../../src/assets/img/f5.png" mode=""/>
					<img src="../../src/assets/img/f6.png" mode=""/>
				</div>
			</div>
			<div class="right">
				<div class="tb" >
					<a >開發者</a>
					</div>
				<div class="tb">
					<a href="https://img.onezpro.cc/web/faq/" target="_blank" rel="noopener noreferrer">FAQ</a>
					</div>
				<div class="tb">
					<a href="https://img.onezpro.cc/web/whitepaper/" target="_blank" rel="noopener noreferrer">白皮書</a>
					</div>
				<div class="tb">
					<a href="https://img.onezpro.cc/web/tos/" target="_blank" rel="noopener noreferrer">
						服務條款
						</a>
					</div>
				<div class="tb">
					<a href="https://img.onezpro.cc/web/user/" target="_blank" rel="noopener noreferrer">隱私協議</a></div>
				<div class="tb xh">
					<a href="mailto:OnezPro@hotmail.com">OnezPro@hotmail.com</a>
					</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class HelloWorld extends Vue {
    @Prop({ default: 0 }) current!:number;
}
</script>

   <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
@font-face {
	font-family: "xiangjiti";
	src: url("../../src/assets/font/kskh.ttf") format('truetype');
	}
	*{
		box-sizing: border-box;
		font-family: "xiangjiti";
	}
	a{
		text-decoration: none;
		color:#fff;
	}
.footer{
	padding-top: 40px;
	max-width: 1187px;
	margin: auto;
	position: relative;
	padding-bottom: 120px;
	.fh{
		font-size: 30px;
		color:#fff;
		text-align: center;
	}
}
.piny{
	padding: 30px 10px;
	// position: absolute;
	display: flex;
	left: 0;
	bottom: 20px;
	width: 100%;
	// margin-top: 100rpx;
	// height:100px;
}
// .flbg{
// }

// .flbg::after {
//     background: linear-gradient(to right, #af8732, #b88e35);
//     content: "";
//     height: 100%;
//     position: absolute;
//     width: 20px;
//     z-index: 2;
// }
// .flbg::before {
//     left: 0;
//     top: 0;
// 		background: #000000;
// 		content: "";
// 		height: 100%;
// 		position: absolute;
// 		width: 20px;
// 		z-index: 2;
// }
// .flbg::after {
//     right: 0;
//     top: 0;
//     transform: rotateZ(180deg);
// }
.trans{
	height: 44px;
	gap: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	// width:100%;
	// padding: 40rpx;
	animation: scroll 30s linear infinite normal;
	flex: 0 0 auto;
	// min-width: 100%;
	img{
		max-width: 170px;
		max-height: 40px;
		// width: 90%;
		flex-shrink: 0;
	}
}
@keyframes scroll{
	0% {
			transform: translateX(0%);
	}
	100% {
			transform: translateX(-100%);
	}
}
	// .xian{
	// 	// position: absolute;
	// 	height: 4rpx;
	// 	width: 100%;
	// 	background:linear-gradient(to right, #6c6c6c, #000000);
	// 	bottom:0;
	// 	left:0;
	// }
	.flist{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		.left{
			color:#fff;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width:20%;
			img{
				width:100px;
				height:100px;
				border-radius: 50%;
				border: 2px solid #ff66c4;
				margin-bottom: 20px;
			}
			div{
				font-size: 20px;
				line-height: 1.5;
                text-align: left;
			}
		}
		.center{
			color:#fff;
			width:33%;
			.ch{
				text-align: center;
				font-size: 30px;
				margin-bottom: 20px;
			}
			.fimg{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				img{
					width:80px;
					height:80px;
					border-radius: 50%;
					margin:10px;
				}
			}
		}
		.right{
			width:20%;
			color:#fff;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.tb{
				font-size: 25px;
				margin-bottom: 5px;
			}
			.xh{
				text-decoration: underline;
			}
		}
	}
	@media (max-width: 790px){
		.flist{
			flex-direction: column;
			.left{
				width:100%;
			}
			.center{
				width:100%;
			}
			.right{
				width:100%;
				align-items:center;
			}
		}
	}
</style>
