<template>
  <div class="content">
		<div class="bg">
			<HelloWorld :current="0"/>
		<!-- <div class="header">
			<img class="logo" src="../../src/assets/img/mr.png" mode=""/>
			<div class="mark" @click="show=false" v-if="show"></div>
			<div class="nav" :class="show?'pos':''">
				<div class="xss" v-if="show"  @click="show=false">X</div>
				<div class="nav-item" v-for="(item,index) in tabs">
					<div class="">
						<span>{{item.name}}</span>
							<div class="" :class="index==current?'line':''"></div>
					</div>
				</div>
			</div>
			<div class="df" @click="show=!show">
				<div class="an">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</div> -->
		<!-- <div class="" style="height: 240rpx;"></div> -->
		<div class="warp">
			<div class="wnac1">
				<div class="w1-left">
					<span>與中華區用戶共同打破傳統商業模式壁壘</span>
					<span>建立一個公平、公開的綜合性</span>
					<span class="cl">區塊鏈DeFi金融社區</span>
				</div>
				<!-- <div class="img"> -->
					<!-- <img id="img" :src="picsrc" mode=""/> -->
					<!-- <img src="../../src/assets/img/result_00001.png" mode=""/> -->
					<!-- <img src="../../src/assets/img/result_00002.png" mode=""/> -->
					<!-- <img src="../../src/assets/img/result_00003.png" mode=""/> -->
					<!-- <img src="../../src/assets/img/Green-poster.gif" mode=""/> -->
				<!-- </div> -->
				<div class="video">
					<div v-if="phone==0">
						<video src="../../src/assets/img/7081a2d3548e43aa893251d05b3e90cc1.mp4"
            muted
            autoplay
            loop
            x5-video-player-fullscreen="true"
            x5-playsinline
            playsinline
            webkit-playsinline></video>
					</div>
					<img v-else src="../../src/assets/img/Green-Screen-poster-720p-unscreen.gif" alt="" srcset="">
				</div>
			</div>
			<div class="xs">
				<div class="xs_item" v-for="(item,index) in list_b" :key="index">
					<span>{{item.tit}}</span>
				</div>
			</div>
		</div>
		</div>
		<div class="bg1">
		<div class="ms1">
			<div class="hed">
				<span>基於區塊鏈的去中心化和DeFi金融對社群經濟X共創經濟</span>
				<span>進行變革和賦能</span>
				<span class="size">為中華區用戶建立共識共創商業生態</span>
			</div>
			<div class="cv">
				<div class="cv1">
					<img src="../../src/assets/img/2.png" mode=""/>
					<span>社群經濟</span>
					<div class="">
						依託OneZ提供的可持續性激勵措施在社交轉<br>
						換中獲得價值，享受社群經濟带来的紅利，<br>
						吸納更多共識者了解和體驗OneZ商業生態。
					</div>
				</div>
				<div class="cv2">
					<img src="../../src/assets/img/3.png" mode=""/>
					<span>共創經濟 </span>
					<div class="">
						通過OneZ商業系統開始市場流通，藉助區塊鏈<br>
						DeFi金融為每一次購物和交易進行增值赋能，<br>
						打造可持續發展的商業生態，實現獲利共存。
					</div>
				</div>
			</div>
		</div>
		</div>
		<div class="bg1">
		<div class="warps">
			<div class="h">
				<span>OneZ利用強大的區塊鏈底層應用技術和豐富的產品功能</span>
				<span>完善社區信任機制</span>
			</div>
			<div class="tm">
				<div class="left">
				<div class="l-item" v-for="(e,i) in list_s" :class="index==i?'active':''" @click="index=i" :key="i">
						<span>{{e.tits}}</span>
					</div>
				</div>
				<div class="right" v-if="index==0">
					<div class="r1">
						<span>客製化 </span>
						客製化可以進一步完善商業生態的信任機制，例如：
						<img class="flx" src="../../src/assets/img/1.d7d00916.webp" mode=""/>
					</div>
					<div class="r2">
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							中心化身分（DID）解決方案為用户自動
							生成OneZ社區網路生成一個節點，擁有其
							屬於其個人的QR Code和邀約碼。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							透過邀約者的OR Code和邀約碼進入OneZ
							的被邀約者都將在邀約者區塊節點中形成
							傘狀向下延伸發展，無法與他人進行交互
							和共享，確保社交轉換價值主導地位。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							分饰式互聯向上向下追溯機制消除差異化導致的信任危機。
							</div>
						</div>
					</div>
				</div>
				<div class="right" v-if="index==1">
					<div class="r1">
						<span>質押機制 </span>
						質押機制確保共創
						經濟體系的可持續
						發展，例如：
						<img class="flx1" src="../../src/assets/img/2.783eb17e.webp" mode=""/>
					</div>
					<div class="r2">
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode="" />
							<div class="">
							OneZ商業生態中，店長與用戶作為共創活
							動發起方與參與者，将共創收益按照一定
							比例鎖定在質押協議並披露公開。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							發起方因任何不當操作導致終止共創，形
							成市場流通出現問题造成參與者资產損
							失，OneZ介入仲裁，透過質押協議鎖定金
							額對參與者進行仲裁賠償。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							透過質押協議公開化披露有效訊號傳導，
							讓市場流通可持續發展並發揮積極的乘數
							效應。
							</div>
						</div>
					</div>
				</div>
				<div class="right" v-if="index==2">
					<div class="r1">
						<span>OTC交易 </span>
						OTC交易簡化了OneZ
						商業生態參與門檻，
						例如：
						<img class="flx2" src="../../src/assets/img/4.628e8140.webp" mode=""/>
					</div>
					<div class="r2">
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							OTC交易模型更好的解決數位貨幣與現實
							交互場景的局限性，為數位貨幣的
							即時流動性提供了一個新的基礎。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							免除了傳統交易所複雜和繁瑣的交易流程
							和校验審查所带來的高频交易手續费和
							大量時間成本，更快速的参與共創活動，
							實現價值轉換、獲利共存。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							OneZ不干涉任何正常OTC交易行為和方式，當交易存疑時，介入調查，透過客裂化分佈式追溯確保交易安全。
							</div>
						</div>
					</div>
				</div>
				<div class="right" v-if="index==3">
					<div class="r1">
						<span>私域流量  </span>
						透過OneZ通訊系統搭建
						個人私域流量社區，例
						如：
						<img class="flx3" src="../../src/assets/img/3.e8393da0.webp" mode=""/>
					</div>
					<div class="r2">
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							OneZ通訊系統基於MTProto協議，使安全
							性與弱連接上的可靠性相容，最大限度提
							高用戶隠私安全，免受駭客攻擊。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							透過極具表現力的自訂展示功能打造屬於
							其個人社區IP，利用趣味性通訊功能和獨
							特的DeFi金融購物增值商集生熊和激属機
							制搭建個人私域流量並擴大自身影響力。
							</div>
						</div>
						<div class="sd">
							<img src="../../src/assets/img/dui.png" mode=""/>
							<div class="">
							OneZ支援图隊24／7持續提供在線支援，與
							用戶共同維護和打造健康可持續發展的社
							區</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
		<div class="bg1">
      <FlooterTb></FlooterTb>
		<div class="floot" v-if="false">
			<div class="fh">核心夥伴</div>
			<!-- <div  style="height: 200rpx;"></div> -->
			<div class="piny" >
				<div class="trans">
					<img src="../../src/assets/logins/8.210e67dd.svg" mode=""/>
					<img src="../../src/assets/logins/1.135b5f7c.svg" mode=""/>
					<img src="../../src/assets/logins/2.20273095.svg" mode=""/>
					<img src="../../src/assets/logins/3.80ef0dd0.svg" mode=""/>
					<img src="../../src/assets/logins/6.87e04b20.svg" mode=""/>
					<img src="../../src/assets/logins/7.0ea7ddc4.svg" mode=""/>
					<img src="../../src/assets/logins/9.ca50e3f1.svg" mode=""/>
					<img src="../../src/assets/logins/11.1f5f6766.svg" mode=""/>
					<img src="../../src/assets/logins/12.e5d051b8.svg" mode=""/>
					<img src="../../src/assets/logins/13.0da5325d.svg" mode=""/>
					<img src="../../src/assets/logins/14.713f544d.svg" mode=""/>
				</div>
				<div class="trans" style="margin-left:80rpx;">
					<img src="../../src/assets/logins/8.210e67dd.svg" mode=""/>
					<img src="../../src/assets/logins/1.135b5f7c.svg" mode=""/>
					<img src="../../src/assets/logins/2.20273095.svg" mode=""/>
					<img src="../../src/assets/logins/3.80ef0dd0.svg" mode=""/>
					<img src="../../src/assets/logins/6.87e04b20.svg" mode=""/>
					<img src="../../src/assets/logins/7.0ea7ddc4.svg" mode=""/>
					<img src="../../src/assets/logins/9.ca50e3f1.svg" mode=""/>
					<img src="../../src/assets/logins/11.1f5f6766.svg" mode=""/>
					<img src="../../src/assets/logins/12.e5d051b8.svg" mode=""/>
					<img src="../../src/assets/logins/13.0da5325d.svg" mode=""/>
					<img src="../../src/assets/logins/14.713f544d.svg" mode=""/>
				</div>
			</div>
			<div class="xian"></div>
			<div class="flist">
				<div class="left">
					<img src="../../src/assets/img/mr.png" mode=""/>
					<div class="tit">
						打破傅统商業模式停暖、設
						定全新交互方式、重新定养
						亚洲数位世代、微底颠覆亚
						洲回有商某生熊系统。
					</div>
				</div>
				<div class="center">
					<div class="ch">聯络Onez</div>
					<div class="fimg">
						<img src="../../src/assets/img/f1.png" mode=""/>
						<img src="../../src/assets/img/f2.png" mode=""/>
						<img src="../../src/assets/img/f3.png" mode=""/>
						<img src="../../src/assets/img/f4.png" mode=""/>
						<img src="../../src/assets/img/f5.png" mode=""/>
						<img src="../../src/assets/img/f6.png" mode=""/>
					</div>
				</div>
				<div class="right">
					<div class="tb">開發者</div>
					<div class="tb">FAQ</div>
					<div class="tb">白皮書</div>
					<div class="tb">服務條款</div>
					<div class="tb">隱私協議</div>
					<div class="tb xh"><a href="mailto:OnezPro@hotmail.com">OnezPro@hotmail.com</a></div>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src
import FlooterTb from '@/components/FlooterTb.vue'
@Component({
  components: {
    HelloWorld,
    FlooterTb
  }
})
export default class Homediv extends Vue {
  public show=false;
  public index=0;
  public phone=0;
  public list_b: { tit: string; }[]=[
    { tit: '＃社群' },
    { tit: '＃購物' },
    { tit: '＃共創' },
    { tit: '＃數位' },
    { tit: '＃加密' },
    { tit: '＃透明' },
    { tit: '＃安全' },
    { tit: '＃DeFi應用' }
  ];

  public list_s: { tits: string; }[]=[
    { tits: '客製化' },
    { tits: '質押機制' },
    { tits: 'OTC交易' },
    { tits: '私域流量' }
  ];

  mounted () {
    console.log('mounted')
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    console.log('是否是Android：', isAndroid)
    console.log('是否是iOS：', isIOS)
    if (isAndroid === true) {
      this.phone = 0
    } else {
      this.phone = 1
    }
  }

  created () {
    console.log('created')
  }
}
</script>

<style lang="less" scoped>
  @font-face {
  font-family: "xiangjiti";
	src: url("../../src/assets/font/kskh.ttf") format('truetype');
	}
	*{
	box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "xiangjiti";
	}
	.content {
		position: relative;
		width: 100%;
		// max-width: 1187px;
		// padding: 0 15px;
		margin: auto;
		font-family: "xiangjiti";
		box-sizing: border-box;
	}

	.logo {
		height: 75px;
		width: 75px;
		border-radius: 50%;
		border: 2px solid #ff66c4;
		margin-right: 30px;
	}
	.bg{
		background:linear-gradient(to right,#000000,#6c6c6c);
		width:100%;
		padding-bottom: 30px;
		padding: 0 15px;
	}
	.header{
		display: flex;
		width: 100%;
		padding:10px 0px ;
		box-sizing: border-box;
		max-width: 1187px;
			margin: auto;
	}
	.nav{
		display: flex;
		align-items: center;
		color: #ffffff;
		width:calc(100%  - 100px);
	}
	.nav-item{
		font-size: 25px;
		font-weight: 500;
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-family:"xiangjiti";
		span{
			padding-bottom: 10px;
			display: inline-block;
		}
	}
	.line{
		height: 2px;
		background-color:#ffffff;
		width: 100%;
	}
	.warp{
		display: flex;
		flex-direction: column;
		max-width: 1187px;
		margin: auto;
	}
	.wnac1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0px;
		.w1-left{
			display: flex;
			flex-direction: column;
			span{
				font-size: 25px;
				line-height: 1.2;
				color:#fff;
				font-family:"xiangjiti";
				margin-bottom: 25px;
			}
			.cl{
				color:#fff;
				font-size: 35px;
				text-shadow: 0px 0px 15px #fe4eda;
			}
		}
		.img{
			width: 300px;
			height: 300px;
			position: relative;
			img{
				top:-65px;
				right:-30px;
				position: absolute;
				width:410px;
				height:410px;
			}
		}
	}
.xs{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.xs_item{
	font-size: 25px;
	color:#fff;
	height: 40px;
}
	.bg1{
		background:linear-gradient(to right, #000000, #6c6c6c);
		width:100%;
		padding: 30px  15px;
	}
.ms1{
	max-width: 1187px;
	margin: auto;
}
.hed{
	display: flex;
	flex-direction: column;
  align-items: flex-start;
	span{
		font-size: 25px;
		color: #fff;
		margin-bottom: 15px;
	}
	.size{
		font-size: 20px;
	}
}
.cv{
	display: flex;
	align-items: center;
	justify-content: center;
	.cv1{
		position: relative;
		border: 1.5px solid #462c44;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 35%;
		padding: 10px 0;
		margin-right: 50px;
		color:#fff;
		img{
			height: 300px;
			height: 300px;
		}
		span{
			font-size: 30px;
			margin-top: 20px;
		}
		div{
			font-size: 16px;
			line-height: 30px;
		}
	}
}
.cv2{
		position: relative;
		border: 1.5px solid #462c44;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 35%;
		padding: 20px 0;
		margin-right: 50px;
		color:#fff;
		// height: 420px;
		img{
			margin: 50px 0 0;
			height: 250px;
			width: 90%;
		}
		span{
			margin-top: 20px;
			font-size: 30px;
		}
		div{
			font-size: 16px;
			line-height: 30px;
		}
	}
	.warps{
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 1187px;
		margin: auto;
		.h{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color:#fff;
			font-size: 30px;
			line-height: 1.4;
		}
	}
	.tm{
		display: flex;
		align-items: center;
		// justify-content: center;
		width: 100%;
		height:460px;
		margin-top: 20px;
		.left{
			.l-item{
				border: 1.5px solid #462c44;
				width: 250px;
				border-radius: 999px;
				height:100px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				color:#fff;
				font-size: 30px;
			}
			.active{
				text-shadow: 0px 0px 20px #fe4eda;
			}
			.l-item:last-child{
				margin: 0;
			}
		}
		.right{
			display: flex;
			align-items: center;
			justify-content: space-between;
			border: 1.5px solid #462c44;
			margin-left: 30px;
			border-radius: 20px;
			padding: 20px;
			height: 100%;
			box-sizing: border-box;
			.sd{
				display: flex;
				align-items: center;
				margin-bottom: 20px;
        text-align: left;
				img{
					width: 50px;
					height: 33px;
					margin-right:10px ;
				}
				div{
					width:calc(100%  - 100px);
				}
			}
			.r1{
				width: 230px;
				// margin-right: 300rpx;
				font-size:25px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				position: relative;
				color:#fff;
        text-align: left;
				span{
					display: block;
					font-size: 30px;
					margin-bottom: 20px;
					text-shadow: 0px 0px 10px #fe4eda;
				}
				.flx{
					width: 340px;
					height:280px;
					margin-bottom: -140px;
					// margin-left: 80rpx;
					margin-top: 65px;
				}
				.flx1{
					width: 400px;
					height:320px;
					margin-bottom: -160px;
					margin-left: -25px;
					margin-top: 55px;
				}
				.flx2{
					width: 400px;
					height: 280px;
					margin-bottom: -160px;
					margin-left: 30px;
					margin-top: 65px;
				}
				.flx3{
					width: 480px;
					height:350px;
					margin-bottom: -200px;
					margin-left: 0px;
					margin-top: 60px;
				}
			}
			.r2{
				color:#fff;
				font-size: 24px;
				width:60%;
			}
		}
	}
.piny{
	padding: 30px 10px;
	// position: absolute;
	display: flex;
	left: 0;
	bottom: 20px;
	width: 100%;
	// margin-top: 100rpx;
	// height:100px;
}
// .flbg{
// }

// .flbg::after {
//     background: linear-gradient(to right, #af8732, #b88e35);
//     content: "";
//     height: 100%;
//     position: absolute;
//     width: 20px;
//     z-index: 2;
// }
// .flbg::before {
//     left: 0;
//     top: 0;
// 		background: #000000;
// 		content: "";
// 		height: 100%;
// 		position: absolute;
// 		width: 20px;
// 		z-index: 2;
// }
// .flbg::after {
//     right: 0;
//     top: 0;
//     transform: rotateZ(180deg);
// }
.trans{
	height: 44px;
	gap: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	// width:100%;
	// padding: 40rpx;
	animation: scroll 30s linear infinite normal;
	flex: 0 0 auto;
	// min-width: 100%;
	img{
		max-width: 169px;
		max-height: 40px;
		// width: 90%;
		flex-shrink: 0;
	}
}
@keyframes scroll{
	0% {
			transform: translateX(0%);
	}
	100% {
			transform: translateX(-100%);
	}
}
.floot{
	padding-top: 40px;
	max-width: 1187px;
	margin: auto;
	position: relative;
	.fh{
		font-size: 25px;
		color:#fff;
		text-align: center;
	}
}
	.xian{
		// position: absolute;
		height: 2px;
		width: 100%;
		background:linear-gradient(to right, #6c6c6c, #000000);
		bottom:0;
		left:0;
	}
	.flist{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 0;
		.left{
			color:#fff;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width:20%;
			img{
				width:100px;
				height:100px;
				border-radius: 50%;
				border:2px solid #ff66c4;
				margin-bottom: 20px;
			}
			div{
				font-size: 16px;
				line-height: 25px;
			}
		}
		.center{
			color:#fff;
			width:33%;
			.ch{
				text-align: center;
				font-size: 20px;
				margin-bottom: 20px;
			}
			.fimg{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				img{
					width:80px;
					height:80px;
					border-radius: 50%;
					margin:10px;
				}
			}
		}
		.right{
			width:20%;
			color:#fff;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.tb{
				font-size: 20px;
				margin-bottom: 5px;
			}
			.xh{
				text-decoration: underline;
			}
		}
	}
// 	.cv1::before {
//   content: '';
//   position: absolute;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
//   z-index: -1;
//   margin: -4px;
//   border-radius: inherit; /*important*/
//   background: linear-gradient(to right, #8F41E9, #578AEF);
// }
.df{
	display: none;
	.an{
		display: flex;
		flex-direction: column;
		span{
			width:25px;
			height: 3px;
			background: #fff;
			margin-bottom: 5px;
			border-radius: 999px;
		}
	}
}
.video{
	position: relative;
	width: 300px;
	height:300px;
	video{
		top:-115px;
		right:0px;
		position: absolute;
		width:480px;
		height:480px;
	}
	img{
		top:-85px;
		right:20px;
		position: absolute;
		width:400px;
		height:400px;
	}
}
@media (max-width: 790px){
	.nav{
		display: none;
	}
	.header{
		align-items: center;
		justify-content: space-between;
	}
	.df{
		display: block;
	}
	.wnac1{
		flex-direction: column;
	}
	.xs{
		flex-wrap: wrap;
	}
	.cv{
		flex-direction: column;
		.cv1{
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
		.cv2{
			width: 100%;
			margin-right: 0;
		}
	}
	.tm{
		flex-direction: column;
		height:auto;
		.left{
			display:flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
		.right{
			flex-direction: column;
			margin-left:0;
			margin-top: 20px;
			.r1{
				margin-right:0;
				.flx{margin: 0;}
        .flx1{margin: 0;}
        .flx2{margin: 0;}
        .flx3{margin: 0;}
			}
			.r2{
				width:100%;
			}
			.sd div {
		width: calc(100% - 60px);
			}
		}
	}
	.flist{
		flex-direction: column;
		.left{
			width:100%;
		}
		.center{
			width:100%;
		}
		.right{
			width:100%;
			align-items:center;
		}
	}
	.pos{
		position: fixed;
		top:0;
		width: 70%;
		height: 100vh;
		right:0;
		display: flex;
		flex-direction: column;
		background-color: #000;
		z-index: 99;
		.nav-item{
			width: 100%;
			display: block;
			padding-left: 30px;
			padding-bottom: 20px;
			.line{
				display: none;
			}
		}
	}
	.mark{
		position: fixed;
		top:0;
		width: 100%;
		height: 100vh;
		left:0;
		z-index:90;
		background-color:rgba(0, 0, 0,0.5);
	}
	.video{
		width: 100%;
		video{
			width: 100%;
		}
		img{
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width:400px;
			height:400px;
			position: absolute;
		}
	}
}
.xss{
	padding: 10px 30px;
	text-align: right;
	width:100%;
}

</style>
