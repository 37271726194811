<template>
 <div class="header">
			<img class="logo" src="../../src/assets/img/mr.png" mode="" />
			<div class="mark" @click="show=false" v-if="show"></div>
			<div class="nav" :class="show?'pos':''">
				<div class="xss" v-if="show"  @click="show=false">X</div>
				<div class="nav-item" @click="tab(0)">
					<div class="">
						<div class="q">首頁</div>
							<div class="" :class="current==0?'line':''"></div>
            </div>
          </div>
				<div class="nav-item" @click="tab(1)">
					<div class="">
						<div class="q">應用程式</div>
							<div class="" :class="current==1?'line':''"></div>
					</div>
				</div>
				<div class="nav-item" @click="tab(2)">
					<div class="">
						<div class="q">
							<a href="https://img.onezpro.cc/web/faq/" target="_blank" rel="noopener noreferrer">FQA</a>
							</div>
							<div class="" :class="current==2?'line':''"></div>
					</div>
				</div>
				<div class="nav-item" @click="tab(3)">
					<div class="">
						<div class="q">開發者<img class="xia" src="../../src/assets/img/xiala.png" mode=""/></div>
							<div class="" :class="current==3?'line':''"></div>
							<div class="ulist" v-if="show_l">
								<div class="uitem">
									<a  href="https://img.onezpro.cc/web/whitepaper/" target="_blank" rel="noopener noreferrer">白皮書1.0</a>
									</div>
								<div class="uitem">
									<a  href="https://img.onezpro.cc/web/tos/" target="_blank" rel="noopener noreferrer">服務條款</a>
									</div>
								<div class="uitem">
									<a  href="https://img.onezpro.cc/web/user/" target="_blank" rel="noopener noreferrer">隱私協議</a>
									</div>
							</div>
					</div>
				</div>
				<div class="nav-item" @click="tab(4)">
					<div class="">
						<div class="q">
							<a href="https://img.onezpro.cc/web/manual/" target="_blank" rel="noopener noreferrer">用戶手冊</a>
						</div>
							<div class="" :class="current==4?'line':''"></div>
					</div>
				</div>
				<div class="nav-item"  @click="tab(5)">
					<div class="">
						<div class="q">關於</div>
							<div class="" :class="current==5?'line':''"></div>
					</div>
				</div>
			</div>
			<div class="df" @click="show=!show">
				<div class="an">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class HelloWorld extends Vue {
  @Prop({ default: 0 }) current!:number;
  public show = false;
  public show_l = false;// @Prop()可以为空，也可以通过@Prop({ default: "default value" })给parentName设置默认值
  tab (i = 0) {
    if (i === this.current) {
      return
    }
    if (i === 0) {
      this.$router.push('/')
    }
    if (i === 1) {
      this.$router.push('/program')
    }
    if (i === 3) {
      this.show_l = !this.show_l
    }
    if (i === 5) {
      this.$router.push('/about')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@font-face {
	font-family: "xiangjiti";
  src:url("../../src/assets/font/kskh.ttf") format('truetype');
}

	*{
		box-sizing: border-box;
		font-family: "xiangjiti";
	}
	.content {
    position: relative;
		width: 100%;
		// max-width: 1187px;
		// padding: 0 15px;
		margin: auto;
		font-family: "xiangjiti";
		box-sizing: border-box;
	}
	a{
		text-decoration: none;
		color:#fff;
	}

	.logo {
		height: 75px;
		width: 75px;
		border-radius: 50%;
		border:2px solid #ff66c4;
		margin-right: 30px;
	}
	.bg{
		background:linear-gradient(to right, #000000, #6c6c6c);
		width:100%;
		padding-bottom: 30px;
		padding: 0 15px;
	}
	.header{
		display: flex;
		width: 100%;
		padding:10px 0px ;
		box-sizing: border-box;
		max-width: 1187px;
		margin: auto;

	}
	.nav{
		display: flex;
		align-items: center;
		color: #ffffff;
		width:calc(100%  - 100px);
		position: relative;
		z-index: 11;
	}
	.nav-item{
		font-size:25px;
		font-weight: 500;
		width: 15%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-family:"xiangjiti";
		div{
			position: relative;
			.ulist{
				position: absolute;
				left: 0;
				bottom: -80px;
				// background:linear-gradient(to right, #000000, #6c6c6c);
				font-size: 20px;
				width: 100%;
				z-index: 11;
				// padding-top: 15px;
				.uitem{
					background:#ffffff;
					color:#555555;
					border-bottom: 1px solid #9d9d9d;
					text-align: center;
					a{
						text-decoration: none;
						color:#000000;
					}
				}
			}
		}
		.q{
			padding-bottom: 10px;
			display: flex;
			align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      user-select:none;
			img{
				width: 20px;
				height: 20px;
			}
		}
	}
	.line{
		height: 2px;
		background-color:#ffffff;
		width: 100%;
	}
	.df{
		display: none;
		.an{
			display: flex;
			flex-direction: column;
			span{
				width:25px;
				height:3px;
				background: #fff;
				margin-bottom: 5px;
				border-radius: 999px;
			}
		}
	}

	@media (max-width: 790px){
		.nav{
			display: none;
		}
		.header{
			align-items: center;
			justify-content: space-between;
		}
		.df{
			display: block;
		}
		.pos{
			position: fixed;
			top:0;
			width: 70%;
			height: 100vh;
			right:0;
			display: flex;
			flex-direction: column;
			background-color: #000;
			z-index: 99;
			.nav-item{
				width: 100%;
				display: block;
				padding-left: 30px;
				padding-bottom: 20px;
				.line{
					display: none;
				}
			}
		}
		.mark{
			position: fixed;
			top:0;
			width: 100%;
			height: 100vh;
			left:0;
			z-index:90;
			background-color:rgba(0, 0, 0,0.5);
		}
		.xss{
			padding: 20px 30px;
			text-align: right;
			width:100%;
		}
		.nav-item div .ulist{
			position: relative;
			bottom: 0;
			background: none;
			padding: 0;
			.uitem{
				background:none;
				border: 0;
				text-align: left;
				padding-left: 20px;
				a{
					text-decoration: none;
					color:#555555;
				}
			}
		}
	}
</style>
