var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../src/assets/img/mr.png"),"mode":""}}),(_vm.show)?_c('div',{staticClass:"mark",on:{"click":function($event){_vm.show=false}}}):_vm._e(),_c('div',{staticClass:"nav",class:_vm.show?'pos':''},[(_vm.show)?_c('div',{staticClass:"xss",on:{"click":function($event){_vm.show=false}}},[_vm._v("X")]):_vm._e(),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(0)}}},[_c('div',{},[_c('div',{staticClass:"q"},[_vm._v("首頁")]),_c('div',{class:_vm.current==0?'line':''})])]),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(1)}}},[_c('div',{},[_c('div',{staticClass:"q"},[_vm._v("應用程式")]),_c('div',{class:_vm.current==1?'line':''})])]),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(2)}}},[_c('div',{},[_vm._m(0),_c('div',{class:_vm.current==2?'line':''})])]),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(3)}}},[_c('div',{},[_vm._m(1),_c('div',{class:_vm.current==3?'line':''}),(_vm.show_l)?_c('div',{staticClass:"ulist"},[_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e()])]),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(4)}}},[_c('div',{},[_vm._m(5),_c('div',{class:_vm.current==4?'line':''})])]),_c('div',{staticClass:"nav-item",on:{"click":function($event){return _vm.tab(5)}}},[_c('div',{},[_c('div',{staticClass:"q"},[_vm._v("關於")]),_c('div',{class:_vm.current==5?'line':''})])])]),_c('div',{staticClass:"df",on:{"click":function($event){_vm.show=!_vm.show}}},[_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"q"},[_c('a',{attrs:{"href":"https://img.onezpro.cc/web/faq/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("FQA")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"q"},[_vm._v("開發者"),_c('img',{staticClass:"xia",attrs:{"src":require("../../src/assets/img/xiala.png"),"mode":""}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uitem"},[_c('a',{attrs:{"href":"https://img.onezpro.cc/web/whitepaper/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("白皮書1.0")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uitem"},[_c('a',{attrs:{"href":"https://img.onezpro.cc/web/tos/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("服務條款")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"uitem"},[_c('a',{attrs:{"href":"https://img.onezpro.cc/web/user/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("隱私協議")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"q"},[_c('a',{attrs:{"href":"https://img.onezpro.cc/web/manual/","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("用戶手冊")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"an"},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }